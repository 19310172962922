<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-12-28 19:10:11
 * @FilePath: \vue2-echart\src\views\main\components\bili\index.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        xData: ["data1", "data2", "data3", "data4", "data5", "data6"],
        seriesData: [
          { value: 735, name: '12-25周岁' },
          { value: 580, name: '25-35周岁' },
          { value: 484, name: '35-50周岁' },
          { value: 300, name: '50周岁以上' }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  height: 100%;
}
</style>