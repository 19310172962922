<template>
    <el-drawer title="内容" :visible.sync="drawer" :direction="direction" :before-close="handleClose" :append-to-body="true">
        <el-row :gutter="10">
            <el-col :span="8" v-for="(item, index) in list" :key="index" @click.stop>
                <el-card>
                    <div @click.stop="changeList(item)" class="item">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image" style="width: 100%;">
                        <div style="padding: 14px;">
                            <span>{{ item.name }}</span>
                        </div>
                        <div class="mask" :class="{'checked':item.show}">
                            <i class="el-icon-success icon"></i>
                        </div>
                    </div>

                </el-card>
            </el-col>
        </el-row>
    </el-drawer>
</template>

<script>
export default {
    props: {
        value: {
            default: false
        },
        list: {
            default: []
        }
    },
    data() {
        return {
            drawer: false,
            direction: 'rtl',
        }
    },
    computed: {

    },
    watch: {
        value: {
            handler(newVal) {
                this.drawer = newVal
            },
            immediate: true

        },
        drawer(val) {
            this.$emit("input", val);
        }
    },
    methods: {
        handleClose(done) {
            done()
        },
        changeList(item) {
            item.show = !item.show
            this.$emit('update:list', this.list)
        },
    }
}
</script>

<style lang="scss" scoped>
.item {
    z-index: 999;
    position: relative;
    cursor: pointer;

    &.checked {
        background: rgba(203, 192, 192, .5);
    }

    .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        transition: all .4s;
        &.checked {
            border: 2px solid #2879FF;
            color: #2879FF;
            font-size: 40px;
            background: rgba(203, 192, 192, .2);
        }

        .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            font-weight: bold;
        }
    }

}
</style>