<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-12-29 14:13:34
 * @FilePath: \vue2-echart\src\views\main\components\bili\chart.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" height="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          color: ['#4EAACC', '#F48634', '#DD1B74', '#772271'],
          title: {
            text: '租房年龄比例',
            left: '10',
            top:'10',
            textStyle: {
              fontSize: 20,
              color: '#fff'
            },
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            top: '40%',
            right: 0
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['40%', '60%'],
              center: ['35%', '50%'],
              avoidLabelOverlap: false,
              // itemStyle: {
              //   borderRadius: 10,
              //   borderColor: '#fff',
              //   borderWidth: 2
              // },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                // label: {
                //   show: true,
                //   fontSize: 40,
                //   fontWeight: 'bold'
                // },
                disabled: false,
                scale: true
              },
              labelLine: {
                show: false
              },
              data: newData.seriesData
            }
          ]
        };

      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>