<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-12-29 13:31:20
 * @FilePath: \vue2-echart\src\views\main\components\jihua\chart.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" height="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler() {
        this.options = {
          title: {
            text: '上半年销售计划',
            left: '10',
            top:'10',
            textStyle: {
              fontSize: 20,
              color: '#fff'
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          // toolbox: {
          //   feature: {
          //     dataView: { show: true, readOnly: false },
          //     magicType: { show: true, type: ['line', 'bar'] },
          //     restore: { show: true },
          //     saveAsImage: { show: true }
          //   }
          // },
          legend: {
            data: ['Evaporation', 'Temperature']
          },
          xAxis: [
            {
              type: 'category',
              data: ['1月', '2月', '3月', '4月', '5月', '6月'],
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '',
              min: 0
            }
          ],
          series: [
            {
              name: '内容1',
              type: 'bar',
              barWidth: "40%",
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value + ' ml';
              //   }
              // },
              data: [102, 230, 300, 450, 350, 375],
              itemStyle: {
                normal: {
                  barBorderRadius: [3, 3, 0, 0], // 柱子圆角
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 1, color: "#0E6AA4" },
                    { offset: 0, color: "#00ADD2" },
                  ]),
                },
              }
            },
            {
              name: '内容2',
              type: 'line',
              // tooltip: {
              //   valueFormatter: function (value) {
              //     return value + ' °C';
              //   }
              // },
              smooth: false,
              data: [102, 230, 300, 450, 350, 375],
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 6,
                    type: 'dotted',
                    color: '#F5A512'
                  }
                }
              }
            }
          ]
        };


      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>