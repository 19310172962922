<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text" style="width: 100%;">嗨小克公寓运营统计数据大屏</span>
              <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text">数据分析1</span>
            </div>
            <div class="react-left ml-3">
              <span class="text">数据分析2</span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b">vue-big-screen</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }} <i class="el-icon-s-tools settings" @click="drawerVisible = true"></i></span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <div class="content-container">
            <draggable v-bind="dragOptions" class="content" :list="showList">
              <div class="item" v-for="(item, index) in showList" :key="index"
                :style="[gridSpan(item.wSpan, item.hSpan)]">
                <dv-border-box-12>
                  <div class="com-item" ref="item_ref">
                    <components :ref='item.component' :is="item.component"></components>
                  </div>
                  <div class="item-footer"></div>
                </dv-border-box-12>
              </div>
            </draggable>

          </div>
        </div>
      </div>
    </div>
    <drawer v-model="drawerVisible" :list.sync="dataList" />
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { formatTime } from '@/utils/index.js'

import draggable from "vuedraggable";
import yonghu from './components/yonghu/index.vue';
import jingjiren from './components/rate/jingjiren.vue'
import jigou from './components/rate/jigou.vue'
import bili from './components/bili/index.vue';
import fangyuan from './components/fangyuan/index.vue'
import jj from './components/jj/index.vue'
import jihua from './components/jihua/index.vue'
import redu from './components/redu/index.vue'
import drawer from '@/components/drawer'

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      dataList: [
        { name: '租房年龄比例', wSpan: 8, hSpan: 3, component: 'yonghu', show: true },
        { name: '共享房源数', wSpan: 8, hSpan: 3, component: 'bili', show: true },
        { name: '活跃经济人比例', wSpan: 8, hSpan: 3, component: 'jingjiren', show: true },
        { name: '共享房源数', wSpan: 8, hSpan: 3, component: 'fangyuan', show: true },
        { name: '实时在线经济人', wSpan: 8, hSpan: 3, component: 'jj', show: true },
        { name: '活跃机构比例', wSpan: 8, hSpan: 3, component: 'jigou', show: true },
        { name: '上半年销售计划', wSpan: 8, hSpan: 3, component: 'jihua', show: true },
        { name: '租房热度', wSpan: 8, hSpan: 3, component: 'redu', show: true },

      ],
      drawerVisible: false
    }
  },
  components: {
    draggable,
    yonghu,
    bili,
    jingjiren,
    jigou,
    fangyuan,
    jj,
    jihua,
    redu,
    drawer
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: {
          name: 'group',
          pull: true,
          put: true
        },
        ghostClass: 'ghost',
        forceFallback: true,
        handle: ".item"
      }
    },
    gridSpan() {
      return (wSpan, hSpan = 3) => {
        return {
          'grid-column-start': `span ${wSpan}`,
          'width': '100%',
          'grid-row-start': `span ${hSpan}`
        }
      }
    },
    showList: {
      get() {
        return this.dataList.filter(item => item.show)
      }, set() {

      }
    }
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.contanier {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  // overflow: hidden;
  overflow: auto;
  //background: url(@/assets/images/bg.jpg) no-repeat top center;
  background-size: cover;

  /* 头部 header */
  .header {
    height: 80px;
    line-height: 80px;
    // background: url(@/assets/images/head_bg.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    position: relative;
    text-align: center;

    h2 {
      flex: 1;
      font-size: 30px;
    }

    p {
      position: absolute;
      right: 30px;
      top: -10px;
    }
  }

  /* 主体 content */
  .content-container {
    position: relative;
    height: 100%;
  }

  .content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-auto-rows: 80px;
    grid-auto-flow: row dense;
    gap: 15px;
    padding: 10px;
    padding-bottom: 20px;
    // height: 100%;

    .item {
      width: 100%;
      // height: 300px;
      overflow: hidden;
      position: relative;
      padding: 5px;
      // margin: 10px;
      box-sizing: border-box;

      .com-item {
        position: relative;
        padding: 10px;
        background-size: cover;
        border: 1px solid rgba(25, 186, 139, 0.17);
        width: 100%;
        // height: 300px;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
      }

      .item-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }



    .ghost {
      opacity: .7;
      background: #E3DAD4;

      span {
        display: none;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
        cursor: pointer;
        // background-color: red;
      }

      &::after {
        color: #fff;
        content: "(^_^)~";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

/* 主体 content */
.content-container {
  position: relative;
  height: 100%;
}

.content {
  position: relative;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-auto-rows: 80px;
  grid-auto-flow: row dense;
  gap: 15px;
  padding: 10px;
  padding-bottom: 20px;
  // height: 100%;

  .item {
    width: 100%;
    // height: 300px;
    overflow: hidden;
    position: relative;
    padding: 5px;
    // margin: 10px;
    box-sizing: border-box;

    .com-item {
      position: relative;
      padding: 10px;
      background-size: cover;
      border: 1px solid rgba(25, 186, 139, 0.17);
      width: 100%;
      // height: 300px;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    .item-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }



  .ghost {
    opacity: .7;
    background: #E3DAD4;

    span {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px;
      cursor: pointer;
      // background-color: red;
    }

    &::after {
      color: #fff;
      content: "(^_^)~";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.settings {
  cursor: pointer;
  color: #fff;
}
</style>
