<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-12-29 13:37:47
 * @FilePath: \vue2-echart\src\views\main\components\jj\chart.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" height="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler() {
        this.options = {
          color: ['#FFBF00'],
          title: {
            text: '实时在线经纪人',
            left: '10',
            top:'10',
            textStyle: {
              fontSize: 20,
              color: '#fff'
            },
          },
          tooltip: {
            trigger: 'axis',
            // axisPointer: {
            //   type: 'cross',
            //   label: {
            //     backgroundColor: '#6a7985'
            //   }
            // }
          },
          legend: {
            show: false,
            data: ['人数']
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {}
          //   }
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['1点', '2点', '3点', '4点', '5点', '6点', '7点', '8点', '9点', '10点', '11点', '12点', '13点', '14点', '15点', '16点', '17点', '18点', '19点', '20点', '21点', '22点', '23点', '24点']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '人数',
              type: 'line',
              stack: 'Total',
              smooth: true,
              // lineStyle: {
              //   width: 4,
              //   color:'#0BBCC7'
              // },
              showSymbol: false,
              label: {
                show: true,
                position: 'top'
              },
              lineStyle: {
                opacity: 0.8,
                width:4,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: '#CA2BA2'
                    
                  },
                  {
                    offset: 1,
                    color: '#0ABCC5'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: [5, 6, 9, 12, 33, 5, 3, 4, 9, 5, 22, 14, 9, 22, 30, 18, 34, 10, 20, 35, 5, 6, 12, 47]
            }
          ]
        };

      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>