<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-12-29 15:41:13
 * @FilePath: \vue2-echart\src\views\main\components\rate\jigou.vue
 * @Description: 
-->
<template>
  <div class="container">
    <div class="main">
      <Rate :tips="55" :colorObj="colorObj" class="left" />
      <div class="right">
        <p>开通机构总数：<span>25</span></p>
        <p>活跃机构总数：<span>25</span></p>
      </div>
    </div>

  </div>
</template>

<script>
import Rate from './rate.vue';
export default {
  data() {
    return {
      cdata: {
        xData: ["data1", "data2", "data3", "data4", "data5", "data6"],
        // seriesData: [
        //   { value: 735, name: '12-25周岁' },
        //   { value: 580, name: '25-35周岁' },
        //   { value: 484, name: '35-50周岁' },
        //   { value: 300, name: '50周岁以上' }
        // ],
      },
      colorObj: {
        textStyle: '#3fc0fb',
        series: {
          color: ['#00bcd44a', 'transparent'],
          dataColor: {
            normal: '#03a9f4',
            shadowColor: 'transparent'
          }
        }
      },
    }
  },
  components: {
    Rate
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;

  .main {
    display: flex;
    height: 100%;
    align-items: center;

    .right {
      color: #fff;
      font-size: 24px;
      width: 60%;

      span {
        color: aqua;
      }

      p+p {
        margin-top: 20px;
      }
    }
  }
}</style>