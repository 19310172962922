<!--
 * @Author: 赛罗
 * @Date: 2023-12-28 18:29:04
 * @LastEditors: 赛罗
 * @LastEditTime: 2023-12-29 14:01:17
 * @FilePath: \vue2-echart\src\views\main\components\redu\chart.vue
 * @Description: 
-->
<template>
  <div class="container">
    <Echart :options="options" id="centreLeft1Chart" height="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler() {
        this.options = {
          title: {
            text: '租房年龄比例',
            left: '10',
            top:'10',
            textStyle: {
              fontSize: 20,
              color: '#fff'
            }
          },
          color: ['#F5A512', '#D03391', '#539CCC'],
          tooltip: {
            trigger: 'item'
          },
          // legend: {
          //   orient: 'vertical',
          //   left: 'left'
          // },
          series: [
            {
              type: 'pie',
              radius: '60%',
              data: [
                { value: 3000, name: '二手房' },
                { value: 3000, name: '毛坯房子' },
                { value: 4000, name: '精装修' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              label:{
                fontSize:16,
                fontWeight:'bold'
              }
            }
          ]
        };


      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>